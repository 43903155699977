<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    >
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :defaultParams="dialogDefaultParams"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
      :multiple="false"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 所属部门 -->
      <template v-slot:accountGroup="{ row }">
        {{ $util.getTreeNames(row.accountGroup, 'name') }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        {{ enableObj[row.enable] }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:所属企业
        {
          tag: 'dialog-define',
          label: this.$t('biManage.targetCompany'),
          prop: 'company',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang:模板名称
        {
          label: this.$t('biManage.biUrl'),
          prop: 'biUrl',
          hidden: false,
          rules: {
            noNull: true,
          }
        }
      ],
      enableObj: this.$util.listToObj(this.$dict.enable()),
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      console.log(this.formData)
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.company = this.tableRow.companyData
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      console.log('confirm', this.formData)
      console.log('cmd', this.cmd)
      // return
      const formData = this.$util.copyData(this.formData)
      formData.companyUid = formData.company.uid
      delete formData.company
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'update':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      console.log(formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.biAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
    editMethod(formData) {
      formData.id = this.tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.biEdit,
        data: formData,
      }).then(() => {
        // lang:编辑成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'edit')
      })
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('biManage.targetCompany')
      this.dialogUrl =  this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:企业名称
        {
          label: 'UID',
          prop: 'uid',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/theme.scss';
.upload-no-img {
  width: 200px;
  height: 200px;
  font-size: 36px;
  line-height: 200px;
  text-align: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  border-radius: 4px;
}
:hover.upload-no-img {
  border: 1px dashed blue;
  color: blue;
}
.upload-has-img {
  width: 200px;
  height: 200px;
  line-height: 200px;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  background: black;
}
.element-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  .field {
    width: 78%;
    border: 5px solid #eeeeee;
    background: #eeeeee;
    margin-right: 5px;
    .field-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .el-input:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .opt {
    i {
      margin-right: 10px;
      cursor: pointer;
      color: $--color-primary;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
</style>
